import * as React from 'react'
import * as ReactRouter from 'react-router-dom'
import PropTypes from 'prop-types'

import * as Common from '@rushplay/common'
import css from '@styled-system/css'
import styled from '@emotion/styled'

import { Button } from '../button'
import { HtmlContent } from '../html-content'

const Wrapper = styled.div`
  position: relative;
  max-width: 1022px;
  width: 100%;
  height: 240px;
  flex-shrink: 0;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 10px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  position: relative;
  background-color: #111540;
  overflow: hidden;
  padding-top: 27px;
  padding-bottom: 26px;
  padding-left: 40px;
  padding-right: 40px;
  border-radius: 14px;

  ${props =>
    css({
      backgroundImage: `url(${props.backgroundImage})`,
    })};

  background-repeat: no-repeat;
  background-position: center;

  @media (max-width: 750px) {
    padding-top: 18px;
    padding-bottom: 18px;
    padding-left: 18px;
    padding-right: 18px;
  }

  @media (max-width: 368px) {
    ${props =>
      css({
        backgroundImage: `url(${props.backgroundImageMobile})`,
      })};
  }
`

const StyledHeader = styled.h1`
  color: white;
  text-align: center;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: 'Rubik';
  font-size: 54px;
  font-style: normal;
  font-weight: 800;
  text-transform: uppercase;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: start;
  z-index: 10;

  ${css({
    '& span': {
      letterSpacing: '0px',
      background: 'linear-gradient(180deg, #feea9c 0%, #f1b14b 100%)',
      'background-clip': 'text',
      '-webkit-background-clip': 'text',
      '-webkit-text-fill-color': 'transparent',
    },
  })}

  @media (max-width: 700px) {
    ${props =>
      css({
        fontSize: props.long ? '28px' : '54px',
      })}

    flex-direction: column;
    max-width: 250px;
  }
`

const OverlayImage1 = styled.div`
  position: absolute;
  right: 0%;
  bottom: 0%;
  width: 100%;
  height: 100%;
  pointer-events: none;
  display: block;

  ${props =>
    css({
      backgroundImage: `url(${props.backgroundImage})`,
    })};

  background-repeat: no-repeat;
  background-position: right;

  @media (max-width: 900px) {
    ${props =>
      css({
        backgroundImage: `url(${props.backgroundImageMobile})`,
      })};
  }
`

export function BannerTemplate(props) {
  return (
    <Wrapper
      backgroundImageMobile={
        props.background?.length > 0 ? props.background[0] : ''
      }
      backgroundImage={props.background?.length > 0 ? props.background[1] : ''}
    >
      {props.prePend ? props.prePend : null}
      <StyledHeader long={Boolean(props.header?.length > 25)}>
        <HtmlContent
          html={{
            __html: props.header || '',
          }}
        />
      </StyledHeader>
      {props.description ? (
        <Common.Box
          color="white"
          fontFamily="Raleway"
          fontSize={['16px', '18px']}
          fontWeight="700"
          pt={'10px'}
          maxWidth={['200px', '100%']}
          lineHeight="20px"
        >
          <HtmlContent
            html={{
              __html: props.description || '',
            }}
          />
        </Common.Box>
      ) : null}
      <Common.Box
        pt={['16px', '20px']}
        style={{
          zIndex: 10,
        }}
      >
        {props.buttonText && (
          <ReactRouter.Link to={props.link || '/'}>
            <Button
              fontSize="14px"
              variant="primary"
              padding={props.largeButton ? '16px 28px' : null}
            >
              {props.buttonText || ''}
            </Button>
          </ReactRouter.Link>
        )}
      </Common.Box>
      {props.overlay?.length > 0 ? (
        <OverlayImage1
          backgroundImage={props.overlay[1]}
          backgroundImageMobile={props.overlay[0]}
          alt="roulette table"
        />
      ) : null}
    </Wrapper>
  )
}

BannerTemplate.propTypes = {
  background: PropTypes.array.isRequired,
  header: PropTypes.string.isRequired,
  description: PropTypes.string,
  overlay: PropTypes.array,
  link: PropTypes.string,
  buttonText: PropTypes.string,
  largeButton: PropTypes.bool,
  prePend: PropTypes.node,
}
